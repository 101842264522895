<template>
<div class="detail-gap">
	<div>
		<a-card :title="article.title" bodyStyle="max-width:1000px;margin:0 auto;" :bordered="false">
		    <div>
          <a-tag color="#108ee9" v-for="item in taglist">{{item}}</a-tag>
				<a-typography>
				    <a-divider>引入</a-divider>
				    <a-typography-paragraph>
				      {{article.major}}
				    </a-typography-paragraph>
					<a-divider orientation="left">我的方案</a-divider>
					</a-typography>
					<div>
						<md-editor v-model="article.info" :previewOnly="true" />
					</div>
					<a-divider orientation="right">{{article.time}}</a-divider>
			</div>
          <a-row justify="end">
      <a-col><Share
	  :url="shareurl"
	  :title="article.title"
	  :origin="shareurl"
	  source="洛天Life"
	  :description="article.major"
	  :image="article.picurl"></Share></a-col>
    </a-row>
		  </a-card>
	</div>
</div>
</template>

<script>
import{ArticleAPI} from '@/api/api';
import { defineComponent } from 'vue';
import MdEditor from 'md-editor-v3';
import 'md-editor-v3/lib/style.css';
export default defineComponent({
  components:{MdEditor},
  data(){
    let id=this.$route.query.id;
    let text='';
    let article={
    };
    let shareurl="";
    let taglist=[];
    return{
      shareurl,
      id,
      text,
      article,
      taglist,
    }
},
  methods:{
    getArticle(id)
    {
      let data={
        id:id,
      }
      ArticleAPI.selectOne(data).then(res=>{
        this.$data.article=res.data;
        this.$data.taglist=res.data.tag.toString().split("/");
      }).catch(err=>{
        alert(err);
      })
    },
  },
  created() {
    this.$data.shareurl=encodeURIComponent("https://www.luotianacg.com/#/articledetail?id="+this.$data.id)
   this.getArticle(this.$route.query.id);
  },
});
</script>

<style scoped>
.detail-gap{
	margin:5vh 5vw 5vh 5vw;
}
</style>