<template>
<div class="anime-main">
    <!-- 番剧更新 -->
    <div style="margin-top:20px;">
        <a-card title="番剧更新" >
             <template #extra><a-button type="primary" @click="switchMode()">{{animeMode}}</a-button></template>
 <div v-if="listSelect==false">
       <a-card-grid style="width:240px;margin:10px;" v-for="(item,index) in animeList " :key="index">
   <a-card :bordered="false" @click="goBofang(item.aurl)">
     <template #cover >
      <a-image  :alt="item.aname" :src="item.apicurl" />
    </template>
    <a-card-meta :title="item.aname">
      <template #description>更新时间：{{item.fulltime}}</template>
    </a-card-meta>
   </a-card>
    </a-card-grid>
 </div>
 <!-- 列表模式 -->
 <div v-if="listSelect==true">
   <a-list item-layout="horizontal" :data-source="animeList">
    <template #renderItem="{ item }">
      <a-list-item>
         <template #actions>
          <a key="list-loadmore-edit" @click="goBofang(item.aurl)">播放</a>
        </template>
        <a-list-item-meta
          :description="'更新时间:'+item.fulltime"
        >
          <template #title>
            <a>{{ item.aname }}</a>
          </template>
          <template #avatar>
            <a-avatar shape="square" size="large" :src="item.apicurl" />
          </template>
        </a-list-item-meta>
      </a-list-item>
    </template>
  </a-list>
 </div>
  </a-card>
    </div>
<!-- 作者感悟 -->
<div class="ganwu">
      <a-card title="笔者感悟" bodyStyle="max-width:1000px;margin:0 auto;" :bordered="false">
    <!-- <template #extra><a href="#">more</a></template> -->
<div v-for="(item,index) in animeReaList" :key="index">
     <a-descriptions :title="item.anime.aname">
        <a-descriptions-item >
  <a-image
     width="200px"
      height="200px"
    :src="item.rpicurl"
  />
    </a-descriptions-item>
      <a-descriptions-item >
        <a-typography>
    <a-typography-title @click="godetail(item.rid)">{{item.rtitle}}</a-typography-title>
      <a-typography-paragraph>
      {{item.rmajor}}
    </a-typography-paragraph>
    </a-typography>
        </a-descriptions-item>
    </a-descriptions>
     <a-divider orientation="right">{{item.rtime}}</a-divider>
    </div>
    <div class="page-footer">
		<a-row type="flex" justify="space-around" align="middle">
			<a-col>
			<a-pagination v-model:current="page" :total="total"  @change="getPage" hideOnSinglePage />
			</a-col>
		</a-row>
</div>
  </a-card>
</div>
</div>
</template>

<script>
import { AnimeAPI, AnimeReaAPI } from '@/api/api'
export default{
    data(){
        return {
            listSelect:true,
            animeMode:"卡片模式",
            page:1,
            total:0,
            animeReaList:[],
            animeList:[],
        }
    },
    methods:{
        godetail(id)
        {
            let data={
                id:id,
            }
            this.$router.push({
                path:"/anime-detail",
                query:data
            })
        },
         getPage(page,pagesize)
			{
				this.getAnimeReaList(page);
			},
        getAnimeReaList(page)
        {
          let data={
            page:page,
          }
          AnimeReaAPI.selectAnimeReaList(data).then(res=>{
            this.$data.animeReaList=res.data.list;
             this.$data.total=res.data.total;
			  this.$data.page=res.data.pageNum;
          })
        },
        switchMode()
        {
          if(this.$data.listSelect==true)
          {
            this.$data.listSelect=false;
            this.$data.animeMode="列表模式";
          }
          else
          {
            this.$data.listSelect=true;
            this.$data.animeMode="卡片模式";

          }
        },
        getAnimeList()
        {
          let aweek=parseInt(new Date().getDay());
          let data={
            week:aweek
          };
          AnimeAPI.selectByWeek(data).then(res=>{
            this.$data.animeList=res.data;

          })
        },
        goBofang(url)
        {
          let data={
            url:url,
          };
          this.$router.push({
            path:"/goelse",
            query:data,
          });
        },

    },
    created(){
      this.getAnimeList();
      this.getAnimeReaList(1);
      
    }  
}
</script>

<style>
.anime-main{
  margin:0 auto;
  max-width:800px;
}
.ganwu
{
    margin-top:5vh;
}
.music-card{
		margin-top: 5vh;
	}
</style>