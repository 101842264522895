<template>
<!--  <div class="person">-->
<!--  </div>-->
  <div class="articlehead">
    <p>洛天Life</p>
  </div>
  <div class="card">
      <a-descriptions title="个人介绍">
        <a-descriptions-item label="作者">洛天</a-descriptions-item>
        <a-descriptions-item label="学校">上海海洋大学</a-descriptions-item>
        <a-descriptions-item label="常居地">闵行区, 上海</a-descriptions-item>
        <a-descriptions-item label="QQ">2731741733</a-descriptions-item>
        <a-descriptions-item label="邮箱">yangbo.sun@outlook.com</a-descriptions-item>
        <a-descriptions-item label="个人理念">
          心如花木，向阳而生
        </a-descriptions-item>
      </a-descriptions>
  </div>
  <div class="card">
    <a-descriptions title="博客介绍">
      <a-descriptions-item label="用途">分享生活与技术</a-descriptions-item>
      <a-descriptions-item label="知识库"><a href="https://luotian2020.gitee.io/luotianacg-wiki/">https://luotian2020.gitee.io/luotianacg-wiki</a></a-descriptions-item>
      <a-descriptions-item label="作者留言">Talk is cheap. Show me your code.</a-descriptions-item>
    </a-descriptions>
  </div>

</template>
<script>
import { StarOutlined, LikeOutlined, MessageOutlined } from '@ant-design/icons-vue';
import { defineComponent } from 'vue';


export default defineComponent({
  components: {
    StarOutlined,
    LikeOutlined,
    MessageOutlined,
  },

  setup() {
    const pagination = {
      onChange: page => {
      },
      pageSize: 3,
    };
    const actions = [{
      type: 'StarOutlined',
      text: '156',
    }, {
      type: 'LikeOutlined',
      text: '156',
    }, {
      type: 'MessageOutlined',
      text: '2',
    }];
    return {
      pagination,
      actions,
    };
  },

});
</script>
<style scoped>
.articlehead{
  margin-top: 5vh;
  text-align: center;
  justify-content: center;
  font-family: "Microsoft YaHei UI";
  font-size: 30px;
}
.person{
  width: 100%;
  height:100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index:-10;
  filter: blur(3px);
  background:url("http://h2.ioliu.cn/bing/SweetheartAbbey_ZH-CN8325969067_1920x1080.jpg");
  background-size: 100%;
}
.card{
  margin-top: 5vh;
  margin-left: 10vw;
  margin-right:10vw;
}
</style>