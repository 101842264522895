<template>
	<!-- <div class="zouma">
		<a-image :preview="false" src="https://file.luotianacg.com/images/pic/b16b0acd-36b5-497f-a1b2-a9fca7dbc1d3.jpg"></a-image>
	</div> -->
	<div class="index-gap">
		<a-row type="flex" justify="space-around" align="top">
			<a-col>
				<div class="index-card">
					<a-card title="杂谈" :bordered="false">
						<template #extra>
							<double-right-outlined style="color: #18aeff;" @click="goTalkBy()" />
						</template>
						<div>
							<a-list item-layout="horizontal" :data-source="talkbyList">
								<template #renderItem="{ item }">
									<a-list-item>
										<a-list-item-meta :description="item.time">
											<template #title>
												<a @click="goTalkByDetail(item.id)">{{ item.title }}</a>
											</template>
										</a-list-item-meta>
									</a-list-item>
								</template>
							</a-list>
						</div>
					</a-card>
				</div>
			</a-col>
			<a-col>
				<div class="index-card">
					<a-card title="技术" :bordered="false">
						<template #extra>
							<double-right-outlined style="color: #18aeff;" @click="goArticle()"/>
						</template>
						<a-list item-layout="horizontal" :data-source="articleList">
							<template #renderItem="{ item }">
								<a-list-item>
									<a-list-item-meta :description="item.time">
										<template #title>
											<a @click="goArticleDetail(item.artid)">{{ item.title }}</a>
										</template>
									</a-list-item-meta>
								</a-list-item>
							</template>
						</a-list>
					</a-card>
				</div>
			</a-col>
			<a-col>
				<div class="index-card">
					<a-card title="动漫" :bordered="false">
						<template #extra>
							<double-right-outlined style="color: #18aeff;" @click="goAnime"/>
						</template>
						<a-list item-layout="horizontal" :data-source="animeReaList">
							<template #renderItem="{ item }">
								<a-list-item>
									<a-list-item-meta :description="item.rtime">
										<template #title>
											<a @click="goAnimeRea(item.rid)">{{ item.rtitle }}</a>
										</template>
									</a-list-item-meta>
								</a-list-item>
							</template>
						</a-list>
					</a-card>
				</div>
			</a-col>
			<a-col>
				<div class="index-card">
					<a-card title="音乐" :bordered="false">
						<template #extra>
							<double-right-outlined style="color: #18aeff;" @click="goMusic()"/>
						</template>
						<a-list item-layout="horizontal" :data-source="musicReaList">
							<template #renderItem="{ item }">
								<a-list-item>
									<a-list-item-meta :description="item.time">
										<template #title>
											<a @click="goMusicRea(item.id)">{{ item.title }}</a>
										</template>
									</a-list-item-meta>
								</a-list-item>
							</template>
						</a-list>
					</a-card>
				</div>
			</a-col>
		</a-row>
	</div>

</template>
<script>
import { AnimeReaAPI, ArticleAPI, MusicReaAPI, TalkByAPI } from '@/api/api';
import { DoubleRightOutlined } from '@ant-design/icons-vue';
export default {
	components: {
		DoubleRightOutlined,
	},
	data() {
		return {
			talkbyList: [],
			articleList: [],
			animeReaList: [],
			musicReaList: [],
		}
	},
	methods: {
		getAnimeReaList()
		{
			AnimeReaAPI.selectIndex().then(res=>{
				this.$data.animeReaList=res.data
			})
		},
		goAnimeRea(id)
		{
			let data={
				id:id
			};
			this.$router.push({
				path:"/anime-detail",
				query:data
			})
		},
		goAnime()
		{
			this.$router.push({
				path:"/anime-share",
			})
			
		},
		getMusicReaList()
		{
			MusicReaAPI.selectIndex().then(res=>{
				this.$data.musicReaList=res.data;
			})
		},
		goMusicRea(id)
		{
			let data={
				id:id
			};
			this.$router.push({
				path:"/music-detail",
				query:data
			})
		},
		goMusic()
		{
			this.$router.push({
				path:"/music-all",
			})
			
		},
			getTalkByList()
		{
			TalkByAPI.selectIndex().then(res=>{
				this.$data.talkbyList=res.data;
			})
		},
		goTalkByDetail(id)
		{
			let data={
				id:id
			};
			this.$router.push({
				path:"/book-detail",
				query:data
			})
		},
		goTalkBy()
		{
			this.$router.push({
				path:"/book-share",
			})
			
		},
		getArticleList()
		{
			ArticleAPI.selectIndex().then(res=>{
				this.$data.articleList=res.data;
			})
		},
		goArticleDetail(id)
		{
			let data={
				id:id
			};
			this.$router.push({
				path:"/articledetail",
				query:data
			})
		},
		goArticle()
		{
			this.$router.push({
				path:"/article",
			})
			
		},

	},
	created(){
		this.getAnimeReaList();
		this.getMusicReaList();
		this.getTalkByList();
		this.getArticleList();
	}
}
</script>
<style scope>
.zouma {
	width: 100%;
	height: 100%;
	margin: 0 auto;

}

.index-gap {
	margin: 5vh 5vw 5vh 5vw;

}

.index-card {
	width: 300px;
	margin-top: 2vh;
}
</style>