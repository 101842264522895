<template>
	<div class="book-gap">
		<div>
			<a-card :title="talkby.title" bodyStyle="max-width:1000px;margin:0 auto;" :bordered="false">
			    
			    <a-typography>
					<a-tag color="#108ee9" v-for="item in tagList">{{item}}</a-tag>
			       <a-divider>major</a-divider>
			        <a-typography-paragraph>
			         {{talkby.major}}
			        </a-typography-paragraph>
			    </a-typography>
				<a-divider></a-divider>
				<div>
				     <md-editor v-model="talkby.info" :previewOnly="true" />
				</div>
				<a-divider orientation="right"> {{talkby.time}}</a-divider>
				         <a-row justify="end">
      <a-col><Share
	  :url="shareurl"
	  :title="talkby.title"
	  :origin="shareurl"
	  source="洛天Life"
	  :description="talkby.major"
	  :image="talkby.picurl"></Share></a-col>
    </a-row>
			  </a-card>
		</div>
	</div>
</template>

<script>
import { TalkByAPI } from '@/api/api';
	import MdEditor from 'md-editor-v3';
	import 'md-editor-v3/lib/style.css';
	export default{
	    components:{MdEditor},
	    data(){
	        return {
				shareurl:"",
				id:this.$route.query.id,
	            talkby:{},
				tagList:[],
	        }
	    },
		methods:{
			getTalkBy()
			{
				let data={
					id:this.$data.id,
				}
				TalkByAPI.selectOne(data).then(res=>{
					this.$data.talkby=res.data;
					this.$data.tagList=res.data.tag.toString().split("/");
				})
			},
		},
		created(){
			 this.$data.shareurl=encodeURIComponent("https://www.luotianacg.com/#/book-detail?id="+this.$data.id)
			this.getTalkBy();
		}
	}
</script>

<style>
	.book-gap{
		margin:5vh 5vw 5vh 5vw;
	}
</style>