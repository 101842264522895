import { createApp } from 'vue'
import Antd from 'ant-design-vue';
import App from './App.vue'
import router from './router'
import 'ant-design-vue/dist/antd.css';
import axios from 'axios';
import qs from 'qs';
import 'font-awesome/css/font-awesome.min.css';
import VueAxios from 'vue-axios';
import 'aplayer/dist/APlayer.min.css';
import APlayer from 'aplayer';
import SocialShare from 'vue3-social-share';//当前版本作者并未使用encodeURIComponent
import 'vue3-social-share/lib/index.css'
import vue3videoPlay from 'vue3-video-play' // 引入组件
import 'vue3-video-play/dist/style.css' // 引入css
const app=createApp(App);
axios.defaults.withCredentials=false;
axios.defaults.baseURL="https://www.luotianacg.com";
app.use(VueAxios, axios).use(router).use(Antd).use(SocialShare).use(vue3videoPlay).mount('#app');
app.config.globalProperties.$axios=axios;
app.config.globalProperties.$qs=qs;
