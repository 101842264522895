import { createRouter, createWebHashHistory } from 'vue-router'
import Index from '../views/index'
import Article from '../views/article'
import About from '../views/about'
import Hobby from '../views/hobby'
import Project from '../views/project'
import Photo from '../views/photo'
import Articledetail  from "@/views/articledetail";
import GameIndex from '../views/gameindex'
import GameDetail from '../views/gamedetail';
import Music from '../views/music';
import Anime from '../views/anime';
import AnimeDetail from '../views/animedetail';
import MusicDetail from '../views/musicdetail';
import Book from '../views/book';
import BookDetail from '../views/bookdetail';
import MusicAll from '../views/musicall';
import GoElse from '../views/goelse';
import Photos from '../views/photos.vue';
import WaterMarkPhoto from "@/views/waterMarkPhoto.vue";

const routes = [
  {
    path: '/',
    name: 'index',
    component: Index,
  },
  {
    path: '/goelse',
    name: 'goelse',
    component: GoElse,
  },
  {
    path: '/article',
    name: 'article',
    component: Article,
  },
  {
    path: '/author',
    name: 'about',
    component: About,
  },
  {
    path: '/hobby',
    name: 'hobby',
    component: Hobby,
  },
  {
    path: '/project',
    name: 'project',
    component: Project,
  },
  {
    path: '/photo',
    name: 'photo',
    component: Photo,
  },
  {
    path: '/articledetail',
    name: 'articledetail',
    component: Articledetail,
  },
  {
    path: '/game',
    name: 'gameindex',
    component: GameIndex,
  },
  {
    path: '/game-detail',
    name: 'game-detail',
    component: GameDetail,
  },
  {
    path: '/music-share',
    name: 'music',
    component: Music,
  },
  {
    path: '/music-all',
    name: 'musicall',
    component: MusicAll,
  },
  {
    path: '/anime-share',
    name: 'anime',
    component: Anime,
  },
  {
    path: '/anime-detail',
    name: 'animedetail',
    component: AnimeDetail,
  },
  {
    path: '/music-detail',
    name: 'musicdetail',
    component: MusicDetail,
  },
  {
    path: '/book-share',
    name: 'book',
    component: Book,
  },
  {
    path: '/book-detail',
    name: 'bookdetail',
    component: BookDetail,
  },
  {
    path: '/photos',
    name: 'photos',
    component: Photos,
  },
  {
    path: '/water-mark-photo',
    name: 'water-mark-photo',
    component: WaterMarkPhoto,
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
