<template>
<div>
		<!-- <div class="zouma">
			<a-carousel autoplay>
			    <div >
					<a-image :preview="false" src="https://file.luotianacg.com/images/pic/d3c3b7dc-c04d-4746-8cc6-40f5d8bd7d02.jpg"></a-image>
				</div>
			   <div >
			   	<a-image :preview="false" src="https://file.luotianacg.com/images/pic/8999c646-2514-4b00-aeac-bfcacf1c5051.jpg"></a-image>
			   </div>
			   <div>
			   	<a-image :preview="false" src="https://file.luotianacg.com/images/pic/66901795-4ec0-4b95-9b75-bbb55aaad9f6.jpg"></a-image>
			   </div>
			  </a-carousel>
		</div> -->
		<div class="photo-main" >
				<a-card :title="photoTheme"  :bordered="false">
			    <template #extra><a @click="switchToPhoto">切换为{{theme}}</a></template>
		<div v-if="listSelect==false">
       <a-card-grid style="width:240px;margin:10px;" v-for="(item,index) in picList " :key="index">
   <a-card :bordered="false">
     <template #cover >
      <a-image  :alt="item.picinfo" :src="item.picurl"/>
    </template>
    <a-card-meta :title="item.picinfo">
      <template #description>{{item.pictime}}</template>
    </a-card-meta>
   </a-card>
    </a-card-grid>
 </div>
 <div v-if="listSelect==true">
			     <a-comment v-for="(item,index) in trendsList" :key="index">
			        <template #actions>
			          <span key="comment-nested-reply-to">{{item.time}}</span>
			        </template>
			        <template #author>
			          <a>洛天</a>
			        </template>
			        <template #avatar>
			          <a-avatar src="https://file.luotianacg.com/images/pic/fa639f29-1928-4875-ac41-b4394523c24c.jpg" alt="luotian" />
			        </template>
			        <template #content>
			          <p>
			           {{item.info}}
			          </p>
					  <a-image-preview-group>
					      <a-image :width="100"  v-for="(item1,index1) in item.picList" :key="index1" :src="item1.picurl" />
					    </a-image-preview-group>
			        </template>
			     </a-comment>
				 </div>
				<div class="page-footer">
		<a-row type="flex" justify="space-around" align="middle">
			<a-col>
			<a-pagination v-model:current="page" :total="total" hideOnSinglePage @change="getPage"/>
			</a-col>
		</a-row>
</div>
			  </a-card>
		</div>
</div>
</template>
<script>
import{TrendsAPI} from "@/api/api";
import { defineComponent } from 'vue';
export default defineComponent({
  data() {
    return {
		total:0,
      selectedKeys: ['2'],
	  photoTheme:'照片动态',
	  theme:"缩略模式",
	  listSelect:true,
	  trendsList:[],
	  picList:[],
	  page:1,
	  pageNum:1,
    };
  },
  methods:{
	  switchToPhoto(){
		  if(this.$data.photoTheme=='照片动态')
		  {
			this.$data.listSelect=false;
			  this.$data.photoTheme='照片宫格';
			  this.$data.theme="动态模式";
		  }
		  else
		  {
			this.$data.listSelect=true;
			  this.$data.photoTheme='照片动态';
			  this.$data.theme="缩略模式";
		  }
	  },
	  getTrends(page)
	  {
		let that=this;
		let data={
			page:page,
		};
		TrendsAPI.selectTrendPics(data).then(res=>{
			this.$data.total=res.data.total;
			this.$data.trendsList=res.data.list;
			that.getPicList(res.data.list);
			that.$data.page=res.data.pageNum;
			that.$data.pageNum=res.data.pages;
		})
	  },
	  getPage(page,pagesize)
			{
				this.getTrends(page);
			},
			getPicList(list)
			{
				let plist=[];
				let n=list.length;
				for(let i=0;i<n;i++)
				{
					plist=plist.concat(list[i].picList);
				}
				this.$data.picList=plist;
			}
  },
created(){
	this.getTrends(1);

}

});
</script>
<style>
	.zouma{
		width: 100%;
		height:100%;
		margin: 0 auto;
		
	}
	.photo-main{
    margin:0 auto;
    max-width:800px;
		border-radius: 1rem;
	}
	.page-footer{
		margin-top: 5vh;
	}
</style>