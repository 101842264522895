<template>
	<a-affix :offset-top="top">
  <a-menu v-model:selectedKeys="current" mode="horizontal">
    <a-menu-item key="mail">
      <template #icon>
        <slack-outlined />
      </template>
      <router-link to="/">洛天Life</router-link>
    </a-menu-item>
    <a-menu-item key="app">
      <template #icon>
        <copy-outlined />
      </template>
      <router-link to="/article">技术</router-link>
    </a-menu-item>
	<a-menu-item key="sub1">
	  <template #icon>
	    <copy-outlined />
	  </template>
	   <router-link to="/photo">相册</router-link>
	</a-menu-item>
		<a-menu-item key="game">
		   <template #icon>
		    <user-outlined />
		  </template>
		 <router-link to="/game">游戏</router-link>
		</a-menu-item>
		<a-menu-item key="anime">
		   <template #icon>
		    <user-outlined />
		  </template>
		 <router-link to="/anime-share">动漫</router-link>
		</a-menu-item>
		<a-menu-item key="music">
		   <template #icon>
		    <team-outlined />
		  </template>
		 <router-link to="/music-all">音乐</router-link>
		</a-menu-item>
		<a-menu-item key="read">
		   <template #icon>
		    <team-outlined />
		  </template>
		 <router-link to="/book-share">杂谈</router-link>
		</a-menu-item>
    <a-menu-item key="work">
       <template #icon>
        <team-outlined />
      </template>
     <router-link to="/project">经历</router-link>
    </a-menu-item>
    <a-menu-item key="water-mark">
      <template #icon>
        <team-outlined />
      </template>
      <router-link to="/water-mark-photo">水印处理</router-link>
    </a-menu-item>
       <a-menu-item key="author">
       <template #icon>
        <team-outlined />
      </template>
     <router-link to="/author">关于</router-link>
    </a-menu-item>
  </a-menu>
	</a-affix>
  <APlayer></APlayer>
  <router-view></router-view>
  <div class="beian">
 <p> Copyright © 2021 - {{year}} luotianAcg &nbsp;</p>
    <p>All Rights Reserved by Luotian.&nbsp;</p>
   <a href="https://beian.miit.gov.cn/" target="_blank" class="huaxian">ICP备案号:&nbsp;&nbsp;</a>
  <a href="https://beian.miit.gov.cn/" target="_blank" class="huaxian">豫ICP备2021028668号 - 2</a>
</div>
</template>
<script>
import { Modal } from 'ant-design-vue';
import APlayer from '@/components/APlayer.vue';
import { defineComponent, ref,h } from 'vue';
import { SlackOutlined, CopyOutlined, UserOutlined ,ToolOutlined,TeamOutlined,HeartOutlined} from '@ant-design/icons-vue';
export default defineComponent({
  components: {
	  APlayer,
    SlackOutlined,
    CopyOutlined,
     UserOutlined,
     ToolOutlined,
     TeamOutlined,
     HeartOutlined
  },

  setup() {

    const current = ref(['mail']);
      const warning = () => {
    Modal.warning({
        title: '洛天Life提醒您',
        content: '因其他项目需要，本网站后端暂时停止服务',
      });
    };
    return {
      warning,
      current,
      endTime:1674316800,//毫秒时间戳
			day:'0',//天
			hour:'00',//时
			min:'00',//分
			second:'00',//秒
      year:new Date().getFullYear()
    };
  },
  methods:{
			// 倒计时
				countTime() {
				  // 获取当前时间
				  let date = new Date()
				  let now = date.getTime()
				  // 设置截止时间
				  let endDate = new Date(this.endTime*1000) // this.endTime需要倒计时的日期
				  let end = endDate.getTime()
				  // 时间差
				  let countdown_time= end - now
				  // 定义变量 d,h,m,s保存倒计时的时间
				  if (countdown_time>= 0) {
				    // 天
				    this.day = Math.floor(countdown_time/ 1000 / 60 / 60 / 24)
				    // 时
				    let h = Math.floor(countdown_time/ 1000 / 60 / 60 % 24)
				    this.hour = h < 10 ? '0' + h : h
				    // 分
				    let m = Math.floor(countdown_time / 1000 / 60 % 60)
				    this.min = m < 10 ? '0' + m : m
				    // 秒
				    let s = Math.floor(countdown_time/ 1000 % 60)
				    this.second = s < 10 ? '0' + s : s
				  } else {
				    this.day = 0
				    this.hour = '00'
				    this.min = '00'
				    this.second = '00'
				  }
				  // 等于0的时候不调用
				  if (Number(this.hour) === 0 && Number(this.day) === 0 && Number(this.min) === 0 && Number(this.second) === 0) {
				    return
				  } else {
					 this.$forceUpdate()//更新DOM
				  // 递归每秒调用countTime方法，显示动态时间效果,
				    setTimeout(this.countTime, 1000)
				  }
				},
  },
  created(){
    // this.warning();
  },
  mounted(){
    this.countTime();
  }

});
</script>
<style lang="less">
::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;height: 0;
}
.beian{
  width: 100%;
  margin-top: 1vh;
  display: inline-block;
  justify-content: center;
  text-align: center;
  align-content: center;
  margin-bottom: 5vh;
}
.huaxian{
  text-decoration : none;
}
//  html {
//       filter: grayscale(1);
//      }

</style>