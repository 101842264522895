<template>
	<div class="music-gap">
		<div class="music-card">
			  <a-card :title="name" bodyStyle="max-width:700px;margin:0 auto;" :bordered="false">
            <a-image :alt="luotianacg" :src="cover" style="max-width: 600px;"/>
			    <template #extra>{{artist}}</template>
			   <a-comment  v-for="(item,index) in musicReaList" :key="index" @click="godetail(item.id)">
			        <template #actions>
			          <span>{{item.time}}</span>
			        </template>
			        <template #content>
			          <p>
			           {{item.major}}
			          </p>
			        </template>
			        </a-comment>
					  <div class="page-footer">
		<a-row type="flex" justify="space-around" align="middle">
			<a-col>
			<a-pagination v-model:current="page" :total="total"  @change="getPage" hideOnSinglePage />
			</a-col>
		</a-row>
</div>
			  </a-card>
		</div>
		
		</div>
</template>
<script>
import { MusicReaAPI } from '@/api/api';
	export default{
		data(){
			return {
				name:this.$route.query.name,
				artist:this.$route.query.artist,
				id:this.$route.query.id,
        cover:this.$route.query.cover,
				musicReaList:[],
				page:1,
				total:0,	
			}
		},
		methods:{
			godetail(id)
			{
				let data={
					id:id,
				}
				this.$router.push({
					path:"/music-detail",
					query:data
				});
			},
			getMusicReaList(page)
			{
			let data={
				page:page,
				mid:this.$data.id,
			};
			MusicReaAPI.selectByMid(data).then(res=>{
			  this.$data.musicReaList=res.data.list;
			  this.$data.total=res.data.total;
			  this.$data.page=res.data.pageNum;
			})

			},
			 getPage(page,pagesize)
			{
				this.getMusicReaList(page);
			},
		},
		created(){
			this.getMusicReaList(1);
		}
	}
</script>
<style>
	.music-gap{
    margin:0 auto;
    max-width:800px;
	}
	.music-card{
		margin-top: 5vh;
    max-width: 700px;
    margin: 0 auto;
	}
</style>