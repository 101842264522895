<template>
    <div style="margin-top:15vh;margin-left:10vw;margin-right: 10vw;margin-bottom: 10vh;">
     <a-card :bordered="false">
     <a-typography>
        <a-divider><warning-outlined style="color: red;font-size: 5vw;"/><a-typography-title style="color:yellow">警告</a-typography-title></a-divider>
    <a-row type="flex" justify="space-around" align="middle">
    <a-col>
       <a-typography-paragraph>
      即将访问网址为<mark style="color:red;user-select: none;">{{url}}</mark>的网站，本网站不保证其安全性。
    </a-typography-paragraph>
    </a-col>
  </a-row>
    </a-typography>
     <a-row type="flex" justify="space-around" align="middle">
    <a-col>
    <a-button type="primary" shape="round" @click="goback()">返回原网址</a-button>
    </a-col>
     <a-col>
    <a-button type="primary" shape="round" @click="goUrl()">访问</a-button>
    </a-col>
  </a-row>
  </a-card>

    </div>
</template>
<script>
import { WarningOutlined} from '@ant-design/icons-vue';
export default{
    components:{
        WarningOutlined,
    },
    data(){
        return{
            url:this.$route.query.url,
        }
    },
    methods:{
        goUrl()
        {
             window.location.href=this.$data.url;
        },
        goback()
        {
            this.$router.go(-1);
        }
    }
}

</script>
<style scoped>

</style>
