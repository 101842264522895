<template>
<div class="all-header">
    <div>
  <a-row type="flex" justify="space-around" align="top">
    <a-col v-for="(item,index) in musicList" :key="index">
       <a-card hoverable style="width:240px;margin-top: 5vh;">
    <template #cover>
      <a-image :alt="item.name" :src="item.cover" />
    </template>
    <a-card-meta :title="item.name" @click="godetail(item.id,item.name,item.artist,item.cover)">
      <template #description>{{item.artist}}</template>
    </a-card-meta>
  </a-card>
    </a-col>
  </a-row>

    </div>
    <div class="page-footer">
		<a-row type="flex" justify="space-around" align="middle">
			<a-col>
			<a-pagination v-model:current="page" :total="total"  @change="getPage" hideOnSinglePage :defaultPageSize="9"/>
			</a-col>
		</a-row>
</div>
</div>
    
</template>
<script>
import { MusicAPI } from '@/api/api';
export default {
    data(){
       return{
         musicList:[],
        page:1,
        total:0
       }
    },
    methods:{
        getMusicList(page)
        {
            let data={
                page:page,
            }
            MusicAPI.selectAll(data).then(res=>{
                this.$data.musicList=res.data.list;
                this.$data.total=res.data.total;
			  this.$data.page=res.data.pageNum;
            })
        },
        getPage(page,pagesize)
			{
				this.getMusicList(page);
			},
            godetail(id,name,artist,cover)
            {
                let data={
                    id:id,
                    name:name,
                    artist:artist,
                    cover: cover
                };
                this.$router.push({
                    path:'/music-share',
                    query:data,
                });
            }
    },
    created(){
        this.getMusicList(1);
    }

}
</script>
<style>
.all-header{
  margin:0 auto;
  max-width:900px;
}
</style>
