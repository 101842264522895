<template>
<div class="article-gap">
	<div class="article-card" v-for="(item,index) in articleList" key="index">
		<a-card :title="item.title" bodyStyle="max-width:1000px;margin:0 auto;" :bordered="false">
			<template #extra><a-button type="primary" @click="godetail(item.artid)">详情</a-button></template>
		   <div>
			   <a-descriptions>
			         <a-descriptions-item >
				  <a-image
				   width="200px"
				    :src="item.picurl"
				  />
			     </a-descriptions-item>
			       <a-descriptions-item >
			         <a-typography>
						 <a-tag color="#87d068" v-for="item1 in item.tag">{{item1}}</a-tag>
						 <a-divider/>
			       <a-typography-paragraph>
			      {{item.major}}
			     </a-typography-paragraph>
				 <a-divider orientation="right"> {{item.time}}</a-divider>
			     </a-typography>
			         </a-descriptions-item>
			     </a-descriptions>
		   </div>
		  </a-card>
	</div>
<div class="page-footer">
		<a-row type="flex" justify="space-around" align="middle">
			<a-col>
			<a-pagination v-model:current="page" :total="total"  @change="getPage" hideOnSinglePage/>
			</a-col>
		</a-row>
</div>
</div>
</template>
<script>
import{ArticleAPI} from '@/api/api';
	export default{
		data(){
			return {
				total:0,
				page:1,
				pageNum:1,
				articleList:[],
				
			}
		},
		methods:{
			godetail(id){
				let data={
					id:id
				};
				this.$router.push({
					path:"/articledetail",
					query:data
				})
			},
			getArticleList(page)
			{
				let that=this;
				let data={
					page:page,
				}
				ArticleAPI.selectAll(data).then(res=>{
					this.getTag(res.data.list);
					this.$data.total=res.data.total;
					that.$data.page=res.data.pageNum;
					that.$data.pageNum=res.data.pages;
				})

			},
			getTag(list)
			{
				let n=list.length;
				let tag="";
				for(let i=0;i<n;i++)
				{
					tag=list[i].tag;
					list[i].tag=tag.toString().split("/");
				}
				this.$data.articleList=list;
			},
			getPage(page,pagesize)
			{
				this.getAllArticle(page);
			},
		},
		created(){
			this.getArticleList(1);
		}
	}

</script>
<style scoped>
	.article-gap{
		margin: 5vh 5vw 5vh 5vw;
	}
	.article-card{
     margin:0 auto;
		max-width: 800px;
		
	}
	.page-footer{
		margin-top: 5vh;
	}
</style>