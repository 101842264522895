<template>
	<div class="project-gap">
		<div class="pro-card" v-for="(item,index) in projectList" :key="index">
			  <a-card :title="item.name"  :bordered="false">
			    <!-- <template #extra></template> -->
			    <div>
			      <a-descriptions >
			        <a-descriptions-item label="项目名称">{{item.name}}</a-descriptions-item>
			        <a-descriptions-item label="项目类型">{{item.type}}</a-descriptions-item>
			        <a-descriptions-item label="开发人员">{{item.developer}}</a-descriptions-item>
			        <a-descriptions-item label="技术应用">{{item.tech}}</a-descriptions-item>
			        <a-descriptions-item label="开发完成时间">{{item.timef}}</a-descriptions-item>
			        <a-descriptions-item label="荣誉">
						{{item.honor}}
			        </a-descriptions-item>
					<a-descriptions-item label="代码链接">
						{{item.url}}
			        </a-descriptions-item>
					<a-descriptions-item label="项目状态">
						<div v-if="item.status==1"><a-badge status="processing" text="Running" /></div>
						<div v-if="item.status==0"><a-badge status="error" text="Stopping" /></div>
					</a-descriptions-item>
			        <a-descriptions-item label="项目意义">{{item.worth}}</a-descriptions-item>
			      </a-descriptions>
			    </div>
			  </a-card>
		</div>
		<!-- <vue3VideoPlay
      v-bind="options"
      poster="https://cdn.jsdelivr.net/gh/xdlumia/files/video-play/ironMan.jpg"
    /> -->
	  <div class="page-footer">
		<a-row type="flex" justify="space-around" align="middle">
			<a-col>
			<a-pagination v-model:current="page" :total="total"  @change="getPage" hideOnSinglePage/>
			</a-col>
		</a-row>
</div>
		
	</div>
</template>

<script>
import { ProjectAPI } from '@/api/api';
import { reactive } from "vue";
export default {
	setup()
	{
		const options = reactive({
  width: "800px", //播放器高度
  height: "450px", //播放器高度
  color: "#409eff", //主题色
  title: "", //视频名称
  src: "https://yun.ssdm.cc/SBDM/SPYFAMILY14.m3u8", //视频源
  type:"m3u8",
  muted: false, //静音
  webFullScreen: false,
  speedRate: ["0.75", "1.0", "1.25", "1.5", "2.0"], //播放倍速
  autoPlay: false, //自动播放
  loop: false, //循环播放
  mirror: false, //镜像画面
  ligthOff: false, //关灯模式
  volume: 0.3, //默认音量大小
  control: true, //是否显示控制
  controlBtns: [
    "audioTrack",
    "quality",
    "speedRate",
    "volume",
    "setting",
    "pip",
    "pageFullScreen",
    "fullScreen",
  ], //显示所有按钮,
});
return{
	options
}
	},
  data(){
	return{
		projectList:[],
		page:1,
		total:0,
	}
  },
  methods:{
	getProjectList(page)
	{
		let that=this;
		let data={
			page:page,
		};
		ProjectAPI.selectAll(data).then(res=>{
			
			this.$data.projectList=res.data.list;
			this.$data.total=res.data.total;
		   that.$data.page=res.data.pageNum;
		})
	},
	getPage(page,pagesize)
			{
				this.getProjectList(page);
			},
  },
  created(){
	this.getProjectList(1);
  }
}
</script>

<style scoped>

.project-gap{
	margin: 5vh 5vw 5vh 5vw;
}
.pro-card{
	margin-top: 5vh;
}
.page-footer{
		margin-top: 5vh;
	}
</style>