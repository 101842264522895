<template>
<div class="article">
     <a-card :bordered="false" bodyStyle="max-width:1000px;margin:0 auto;">
     <a-typography>
    <a-typography-title>{{animeRea.rtitle}}</a-typography-title>
    <a-typography-paragraph>
     {{animeRea.rmajor}}
    </a-typography-paragraph>
    <a-divider orientation="right">{{anime.aname}}</a-divider>
    </a-typography>
    <div>
         <md-editor v-model="animeRea.rinfo" :previewOnly="true" />
    </div>
    <a-divider orientation="right" orientation-margin="50px">写于{{animeRea.rtime}}</a-divider>
    <a-row justify="end">
      <a-col><Share
	  :url="shareurl"
	  :title="animeRea.rtitle"
	  :origin="shareurl"
	  source="洛天Life"
	  :description="animeRea.rmajor"
	  :image="anime.apicurl"></Share></a-col>
    </a-row>
    </a-card>
    <a-card title="番剧详情" bodyStyle="max-width:1000px;margin:0 auto;" :bordered="false">
			<template #extra><a-button type="primary" @click="goOpen(anime.aurl)">详情</a-button></template>
		   <div>
			   <a-descriptions>
			         <a-descriptions-item >
				  <a-image
				   width="240px"
				    :src="anime.apicurl"
				  />
			     </a-descriptions-item>
			       <a-descriptions-item >
			         <a-typography>
			       <a-typography-paragraph>
			      {{anime.ainfo}}
			     </a-typography-paragraph>
			     </a-typography>
			         </a-descriptions-item>
			     </a-descriptions>
		   </div>
		  </a-card>
</div>
</template>

<script>
import MdEditor from 'md-editor-v3';
import 'md-editor-v3/lib/style.css';
import { AnimeAPI, AnimeReaAPI } from '@/api/api';
export default{
    components:{MdEditor},
    data(){
        return {
            id:this.$route.query.id,
            shareurl:"",
            animeRea:{ },
            anime:{},
            articleinfo:"## 1223312213"
        }
    },
    methods:{
        getAnimeRea()
        {
            let data={
                rid:this.$data.id,
            };
            AnimeReaAPI.selectOne(data).then(res=>{

                this.$data.animeRea=res.data;
                this.getAnime(res.data.aid);
            })
        },
        getAnime(id)
        {
            let data={
                aid:id
            };
            AnimeAPI.selectOne(data).then(res=>{
                this.$data.anime=res.data;
            })
        },
        goOpen(url)
        {
           let data={
            url:url,
          };
          this.$router.push({
            path:"/goelse",
            query:data,
          });
        }
    },
    created(){
        this.$data.shareurl=encodeURIComponent("https://www.luotianacg.com/#/anime-detail?id="+this.$data.id)
        this.getAnimeRea();
    }
}
</script>

<style>
.article{
    margin:5vh 5vw 5vh 5vw;
}
</style>