<template>
	<div>
		<a-row type="flex" justify="space-around" align="middle" >
    <a-col v-for="(item,index) in gameList" :key="index" >
       <a-card hoverable  style="width:240px;margin-top: 5vh;">
    <template #cover>
      <a-image :alt="item.name" :src="item.picurl" />
    </template>
    <a-card-meta :title="item.name" @click="godetail(item.id)">
      <template #description>{{item.developer}}</template>
    </a-card-meta>
  </a-card>
    </a-col>
  </a-row>
	</div>
  <div class="page-footer">
		<a-row type="flex" justify="space-around" align="middle">
			<a-col>
			<a-pagination v-model:current="page" :total="total" hideOnSinglePage @change="getPage"/>
			</a-col>
		</a-row>
</div>
</template>

<script>
import {GameAPI} from '@/api/api';
export default{
	data(){
		return {
      page:1,
				pageNum:1,
        total:10,
			gameList:["原神","崩坏3"],
		}
	},
	methods:{
		godetail(id)
		{
			let data={
				gid:id
			}
			this.$router.push({
				path:"/game-detail",
				query:data
			})
		},
    getGameList(page)
    {
      let that=this;
      let data={
        page:page
      };
      GameAPI.selectAll(data).then(res=>{
        this.$data.total=res.data.total;
        this.$data.gameList=res.data.list;
        that.$data.page=res.data.pageNum;
					that.$data.pageNum=res.data.pages;
      })
    },
    getPage(page,pagesize)
			{
				this.getGameList(page);
			},
	
	},
  created(){
    this.getGameList(1);
  }
	
}
</script>

<style>
.game-header{
	margin-top: 20vh;
}
.page-footer{
		margin-top: 5vh;
	}
</style>