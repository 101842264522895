<template>
  <div id="app">
    <h1>照片水印添加</h1>
    <div class="upload-file">
      <input type="file" @change="handleUpload" accept="image/*" id="upload" class="input-file">
      <span class="tip">点击上传图片</span>
    </div>

    <a-input addon-before="第一行水印文字"  style="width: 50%;margin-bottom: 20px;margin-top: 20px;" v-model:value="waterMark1" />
    <a-input addon-before="第二行水印文字" style="width: 50%;margin-bottom: 20px;" v-model:value="waterMark2" />
    <canvas ref="canvas" :width="state.canvasWidth" :height="state.canvasHeight" id="canvas"></canvas>
    <!--    <button @click="saveImage">保存图片</button>-->
    <a-button type="primary" shape="round" @click="saveImage" style="width: 30%">保存图片</a-button>
    <div v-show="state.downloadMessage" id="downloadMessage">图片已下载</div>
  </div>
</template>

<script>
import { ref, reactive, onMounted, nextTick } from 'vue';
import EXIF from 'exif-js';


export default {
  name: 'PhotoWatermark',
  setup() {
    const canvas = ref(null);
    const ctx = ref(null);
    const waterMark1=ref('');
    const waterMark2=ref('');
    const i=-1;
    const state = reactive({
      originalFileName: 'watermarked-image.png',
      canvasWidth: 200,
      canvasHeight: 200,
      downloadMessage: false,
    });
    onMounted(() => {
      if(canvas.value){
        ctx.value = canvas.value.getContext('2d');
      }
    });
    const loadImage=function (img) {
      return new Promise(function(resolve, reject) {
        let info=null;
        img.onload = function() {
          EXIF.getData(img,()=>{
             info=EXIF.getAllTags(this);
            waterMark1.value=info.Model;
            waterMark2.value=info.Copyright;
          })
          resolve(info); // 图片加载完成，将img对象传递给resolve
        };
        img.onerror = function() {
          reject(new Error('Image load failed')); // 图片加载失败，reject带上错误信息
        };
      });
    };

    const handleUpload = function (event)  {
      const file = event.target.files[0];
      if (file) {
        state.originalFileName = file.name.replace(/\.[^/.]+$/, '') + '-watermarked.png';
        const img = new Image();
        const url = URL.createObjectURL(file);
        if(waterMark1.value===''&&waterMark2.value===''){
          let img1=new Image();
          img1.src=URL.createObjectURL(file)
          loadImage(img1).then(res=> {
            img.src = url;
            img.onload = async () => {
              const originalWidth = img.width;
              const originalHeight = img.height;
              const marginRatio = 0.1;
              const margin = Math.min(originalWidth, originalHeight) * marginRatio;

              state.canvasWidth = originalWidth + margin * 2;
              state.canvasHeight = originalHeight + margin * 2;

              await nextTick(); // Ensure the canvas size is updated before drawing

              ctx.value = canvas.value.getContext('2d');
              ctx.value.clearRect(0, 0, state.canvasWidth, state.canvasHeight);

              console.log(`Canvas size: ${state.canvasWidth}x${state.canvasHeight}`);
              console.log(`Image size: ${originalWidth}x${originalHeight}`);

              // Draw the original image in the center with a margin

              ctx.value.drawImage(img, margin, margin, originalWidth, originalHeight);
              createBlurredBorder(img, margin, originalWidth, originalHeight);
              addWatermark(originalWidth, originalHeight, margin);
            }
          })
        }
       else{
          img.src=url;
          img.onload =async ()=> {
            const originalWidth = img.width;
            const originalHeight = img.height;
            const marginRatio = 0.1;
            const margin = Math.min(originalWidth, originalHeight) * marginRatio;

            state.canvasWidth = originalWidth + margin * 2;
            state.canvasHeight = originalHeight + margin * 2;

            await nextTick(); // Ensure the canvas size is updated before drawing

            ctx.value = canvas.value.getContext('2d');
            ctx.value.clearRect(0, 0, state.canvasWidth, state.canvasHeight);

            console.log(`Canvas size: ${state.canvasWidth}x${state.canvasHeight}`);
            console.log(`Image size: ${originalWidth}x${originalHeight}`);

            // Draw the original image in the center with a margin

            ctx.value.drawImage(img, margin, margin, originalWidth, originalHeight);
            createBlurredBorder(img, margin, originalWidth, originalHeight);
            addWatermark(originalWidth, originalHeight, margin);
          };
          img.onerror = () => {
            console.error('Failed to load image');
          };
      }
       }
    };

    const createBlurredBorder = (img, margin, originalWidth, originalHeight) => {
      ctx.value.drawImage(img, 0, 0, state.canvasWidth, state.canvasHeight);
      for (let i = 0; i < 10; i++) {
        ctx.value.filter = 'blur(20px)';
        ctx.value.globalAlpha = 0.5;
        ctx.value.drawImage(canvas.value, 0, 0, state.canvasWidth, state.canvasHeight);
      }
      ctx.value.filter = 'none';
      ctx.value.globalAlpha = 1;
      ctx.value.clearRect(margin, margin, originalWidth, originalHeight);
      ctx.value.drawImage(img, margin, margin, originalWidth, originalHeight);
    };

    const addWatermark = (originalWidth, originalHeight, margin) => {
      const cameraInfo = waterMark1.value+'\n \n'+waterMark2.value;
      const lines = cameraInfo.split('\n');
      let fontSize=(7/680)*state.canvasWidth;
      let lineFontHeight=(1/100)*state.canvasHeight;

      ctx.value.font = fontSize+'px Microsoft YaHei';
      ctx.value.fillStyle = 'white';
      ctx.value.textAlign = 'center';
      ctx.value.textBaseline = 'middle';

      const textY = margin + originalHeight + (state.canvasHeight - (margin + originalHeight)) / 4;
      const lineHeight = lineFontHeight;
      lines.forEach((line, index) => {
        ctx.value.fillText(line, state.canvasWidth / 2, textY + (index * lineHeight)+index*10);
      });
    };

    const saveImage = () => {
      const link = document.createElement('a');
      link.download = state.originalFileName;
      link.href = canvas.value.toDataURL();
      link.click();
      state.downloadMessage = true;
      setTimeout(() => {
        state.downloadMessage = false;
      }, 3000);
    };

    return {
      waterMark1,
      waterMark2,
      canvas,
      state,
      handleUpload,
      saveImage,
    };
  },
};
</script>

<style scoped>
#app {
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 20px;
}

#upload {
  margin-bottom: 20px;
}

#canvas {
  border: 1px solid #ccc;
  margin-bottom: 20px;
}

button {
  margin-bottom: 20px;
}

#downloadMessage {
  color: green;
  font-size: 18px;
}
.upload-file{
  position: relative;
  width: 120px;
  padding: 10px 15px;
  border: 1px solid rgb(119, 154, 80);
  border-radius: 5px;
  background-color: #66ccff;
  color: #333333;
  font-size: 14px;
  text-align: center;
  overflow: hidden;
}

.upload-file span{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.upload-file:hover{
  font-size: 15px;
  border-color: rgb(39, 226, 81);
}

.upload-file input[type='file']{
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  cursor: pointer;
}

</style>
