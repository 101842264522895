<template>
  <div class="headtitle">
    <a-divider>  <a-select
        v-model:value="value"
        label-in-value
        style="width: 120px"
        :options="options"
        @change="handleChange"
    ></a-select></a-divider>
  </div>
  <div>
    <div  v-if="flag==1">
      <Waterfall :list="list">
        <template #item="{ item }">
          <div class="card">
            <a-card hoverable>
              <template #cover>
                <a-image
                    :src="item.picurl"
                />
              </template>
              <template #actions>
                <question-circle-outlined  @click="showModal(item)"/>
              </template>
              <a-card-meta :title="item.title">
                <template #description>
                  <p class="animetext">
                    {{item.info}}
                  </p>
                </template>
              </a-card-meta>
            </a-card>
          </div>
        </template>
      </Waterfall>
    </div>
    <div  v-if="flag==0">
      <Waterfall :list="list">
        <template #item="{ item }">
          <div class="card">
            <a-card hoverable>
              <template #cover>
                <a-image
                    :src="item.picurl"
                />
              </template>
              <template #actions>
                <question-circle-outlined  @click="showModal(item)"/>
                <play-circle-outlined @click="goplayer(item.url)"  />
              </template>
              <a-card-meta :title="item.title" >
                <template #description >
                  <p class="animetext">
                    {{item.info}}
                  </p>
                </template>
              </a-card-meta>
            </a-card>
          </div>
        </template>
      </Waterfall>
    </div>
  </div>
  <a-pagination @change="onChange" :total="total" style="margin-top: 10vh;text-align: center;align-items: center;justify-items: center" />
  <div>
    <a-modal
        v-model:visible="visible"
        :title="title"
        width="100%"
        wrap-class-name="full-modal"
        @ok="handleOk"
    >
      <a-image
      :src="imgurl"
      style="float: left" width="300px">

      </a-image>
      <p style="margin-top: 5vh;display: block;">{{info}}</p>
    </a-modal>
  </div>
</template>

<script>
import { Waterfall } from 'vue-waterfall-plugin-next';
import 'vue-waterfall-plugin-next/dist/style.css';
import {  QuestionCircleOutlined,PlayCircleOutlined } from '@ant-design/icons-vue';
import { defineComponent,ref } from 'vue';
export default defineComponent({
  components: {
    QuestionCircleOutlined,
    PlayCircleOutlined,
    Waterfall
  },
  setup(){
   let options = ref([{
      value: '动漫',
      label: '动漫',
    },
      {
        value: '游戏',
        label: '游戏',
      }
    ]);
    const visible = ref(false);
    const info=ref("");
    const title=ref("");
    const imgurl=ref("");

    const showModal = (item) => {
      visible.value = true;
      info.value=item.info;
      imgurl.value=item.picurl;
      title.value=item.title;

    };

    const handleOk = e => {
      visible.value = false;
    };
    return {
      value:ref({
        value: '动漫',
      }),
      options,
      visible,
      info,
      title,
      imgurl,
      showModal,
      handleOk,
    };

  },
  data(){
    let total=10;
    let flag=0;
    let list=[];

  return {
    list,
    flag,
    total,
  }

  },
  methods:{
    getAnime(n)
    {
      let data={
        page:n
      };
      this.$axios.post("/anime/all",this.$qs.stringify(data)).then(res=>{
        this.$data.total=res.data.total;
        this.$data.list=res.data.list;

      }).catch(err=>
      {
        alert(err);
      })
    },
    getgame(n)
    {
      let data={
        page:n
      };
      this.$axios.post("/game/all",this.$qs.stringify(data)).then(res=>{
        this.$data.total=res.data.total;
        this.$data.list=res.data.list;

      }).catch(err=>
      {
        alert(err);
      })

    },
    handleChange (value) {
      if(value.value=="游戏")
      {
        this.$data.flag=1;
        this.getgame(1);
      }
      else {
        this.$data.flag=0;
        this.getAnime(1);
      }
    },
    onChange(page)
    {
      if(this.$data.flag==0)
      {
        this.getAnime(page);
      }
      else
      {
        this.getgame(page);
      }
    },
    goplayer(url)
    {
      window.location.href=url;
    }
  },
  created() {
    this.getAnime(1);
  }
});
</script>

<style lang="less" >
.headtitle{
  margin-top: 5vh;
}
.card{
  margin:1rem;
}
.animetext{
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  word-break: break-all;
}
.full-modal {
.ant-modal {
  max-width: 100%;
  top: 0;
  padding-bottom: 0;
  margin: 0;
}
.ant-modal-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.ant-modal-body {
  flex: 1;
}
}

</style>