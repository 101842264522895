<template>
<div class="game-main">
<div>
    <a-descriptions :title="game.name">
        <a-descriptions-item >
  <a-image
    :width="200"
    :src="game.picurl"
  />
    </a-descriptions-item>
        <a-descriptions-item >
            <a-typography direction="vertical">
                <a-typography-text>上市时间:{{game.time}}</a-typography-text>
                <br/>
                <a-typography-text>游戏类型:{{game.type}}</a-typography-text>
                <br/>
                <a-typography-text>厂商:{{game.developer}}</a-typography-text>
                <br/>
                <a-typography-link :href="game.url" target="_blank">
    官网:{{game.url}}
    </a-typography-link>
    <a-divider>游戏背景</a-divider>
   {{game.info}}
            </a-typography>
    </a-descriptions-item>
    </a-descriptions>
</div>
<div>
    <a-card  :bordered="false">
    <!-- <template #extra><a href="#">more</a></template> -->
       <a-comment v-for="(item,index) in gtrendsList" :key="index">
			       <template #actions>
			         <span key="comment-nested-reply-to">{{item.gttime}}</span>
			       </template>
			       <template #author>
			         <a>洛天</a>
			       </template>
			       <template #avatar>
			         <a-avatar src="https://file.luotianacg.com/images/pic/2022/08/24/6bdf385a39f048888b90f57a00210634.jpg" alt="luotian" />
			       </template>
			       <template #content>
			         <p>
			         {{item.gtinfo}}
			         </p>
					 <a-image-preview-group>
					     <a-image  style="max-width:240px;" v-for="(item1,index1) in item.picList" :key="index1" :src="item1.picurl" />
					   </a-image-preview-group>
			       </template>
			    </a-comment>
          <div class="page-footer">
		<a-row type="flex" justify="space-around" align="middle">
			<a-col>
			<a-pagination v-model:current="page" :total="total"  @change="getPage" hideOnSinglePage/>
			</a-col>
		</a-row>
</div>
  </a-card>
</div>
</div>
	
</template>

<script>
import { GameAPI,GtrendsAPI } from '@/api/api';
export default{
    data(){
        return {
          page:1,
          gid:this.$route.query.gid,
          game:{},
          gtrendsList:[],
          total:0,
        }
    },
    methods:{
      getGame()
      {
        let data={
          id:this.$data.gid,
        }
        GameAPI.selectOne(data).then(res=>{
          this.$data.game=res.data;
        })
      },
      getGtrendsList(page)
      {
        let that=this;
        let data={
          gid:this.$data.gid,
          page:page
        };
        GtrendsAPI.selectGame(data).then(res=>{
          this.$data.total=res.data.total;
					that.$data.page=res.data.pageNum;
          this.$data.gtrendsList=res.data.list;
        })

      },
      getPage(page,pagesize)
			{
				this.getGtrendsList(page);
			},
    },
    created(){
      this.getGame();
      this.getGtrendsList(1);
    }
}
</script>

<style>
.game-main{
  margin:0 auto;
  max-width:1000px;
}
</style>