<template>
	<div clas="book-gap">
		<div class="book-main" v-for="(item,index) in talkbyList" :key="index">
			<a-card  @click="godetail(item.id)" bodyStyle="max-width:1000px;margin:0 auto;" :bordered="false">
			 <template #extra><a-tag color="#108ee9" v-for="(item1,index1) in item.tag" :key="index1">{{item1}}</a-tag></template>
			   <div>
				   <a-descriptions :title="item.title">
				         <a-descriptions-item >
					  <a-image
					   width="200px"
					    :src="item.picurl"
					  />
				     </a-descriptions-item>
				       <a-descriptions-item >
				         <a-typography>
							  <a-divider orientation="right">{{item.time}}</a-divider>
				       <a-typography-paragraph>
				       {{item.major}}
				     </a-typography-paragraph>
				     </a-typography>
				         </a-descriptions-item>
				     </a-descriptions>
			   </div>
			  </a-card>
		</div>
		<div class="page-footer">
		<a-row type="flex" justify="space-around" align="middle">
			<a-col>
			<a-pagination v-model:current="page" :total="total"  @change="getPage" hideOnSinglePage/>
			</a-col>
		</a-row>
</div>
	</div>
</template>

<script>
import { TalkByAPI } from '@/api/api';
	export default{
		data(){
			return {
				talkbyList:[],
				page:1,
				total:0,
			}
		},
		methods:{
			godetail(id){
				let data={
					id:id,
				}
				this.$router.push({
					path:"/book-detail",
					query:data
				})
			},
			getTalkByList(page)
			{
				let data={
					page:page,
				};
				TalkByAPI.selectAll(data).then(res=>{
					this.getTag(res.data.list);
					this.$data.total=res.data.total;
					this.$data.page=res.data.pageNum;
				})
			},
			getPage(page,pagesize)
			{
				this.getTalkByList(page);
			},
				getTag(list)
			{
				let n=list.length;
				let tag="";
				for(let i=0;i<n;i++)
				{
					tag=list[i].tag;
					list[i].tag=tag.toString().split("/");
				}
				this.$data.talkbyList=list;
			},
		},
		created(){
			this.getTalkByList(1);
		}
	}
</script>

<style>
	.book-gap{
		margin: 5vh 5vw 5vh 5vw;
	}
	.book-main{
    margin:0 auto;
		max-width:800px;
		
	}
	.page-footer{
		margin-top: 5vh;
	}
	
</style>