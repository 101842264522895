<template>
	<div class="main-detail">
		<div>
			<a-card :title="music.name" bodyStyle="max-width:1000px;margin:0 auto;" :bordered="false">
			    <template #extra>{{music.artist}}</template>
			   <div>
				   <a-typography>
				       <a-typography-title>{{musicRea.title}}</a-typography-title>
				       <a-typography-paragraph>
				        {{musicRea.major}}
				       </a-typography-paragraph>
					   <a-divider></a-divider>
				   </a-typography>
				   <div>
					    <md-editor v-model="musicRea.info" :previewOnly="true" />
				   </div>
				   <a-divider orientation="right">{{musicRea.time}}</a-divider>
			   </div>
			   	  <a-row justify="end">
      <a-col><Share
	  :url="shareurl"
	  :title="musicRea.title"
	  :origin="shareurl"
	  source="洛天Life"
	  :description="musicRea.major"
	  :image="music.cover"></Share></a-col>
    </a-row>
			  </a-card>
		</div>
	</div>
</template>
<script>
	import MdEditor from 'md-editor-v3';
	import 'md-editor-v3/lib/style.css';
import { MusicAPI, MusicReaAPI } from '@/api/api';
	export default{
	    components:{MdEditor},
	    data(){
	        return {
				id:this.$route.query.id,
				musicRea:{},
				music:{},
				shareurl:"",
	        }
	    },
		methods:{
			getMusicRea()
			{
				let data={
					id:this.$data.id,
				};
				MusicReaAPI.selectOne(data).then(res=>{
					this.$data.musicRea=res.data;
					this.getMusic(res.data.mid);
				});
			
			},
			getMusic(id)
			{
				let data1={
					id:id,
				}
				MusicAPI.selectOne(data1).then(res=>{
					this.$data.music=res.data;
				})
			}
		},
		created(){
			this.$data.shareurl=encodeURIComponent("https://www.luotianacg.com/#/music-detail?id="+this.$data.id);
			this.getMusicRea();
		}
	}
</script>
<style>
	.main-detail{
		margin: 5vh 5vw 5vh 5vw;
	}
</style>